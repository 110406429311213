<template>
	<!-- 项目经理-项目管理 -->
	<div class="container">
		<GModule>
			<a-button class="clo2" style="margin-bottom: 10px;" @click="openAdd()"><plus-outlined />创建项目</a-button>
			<a-table
				class="ttable" 
				sticky 
				:columns="columnsHe" 
				:data-source="list" 
				:scroll="{ x: 500,y:450 }" 
				bordered
				:pagination="false">
				<template #bodyCell="{ column ,record}">
					<template v-if="column.dataIndex === 'team_arr'">
						<div class="team">
							<div v-for="(itm,ind) in record.team_arr" :key="ind">
								{{itm.name}} 
								<span v-if="ind < record.team_arr.length-1">,</span> 
							</div>
						</div>
					</template>
					<template v-if="column.dataIndex === 'urls_arr'">
						<div class="team">
							<div v-for="(uitm,uind) in record.urls_arr" :key="uind">
								<a :href="uitm.url" target="_blank">{{uitm.name}}</a>
								<span v-if="uind < record.urls_arr.length-1">,</span> 
							</div>
						</div>
					</template>
				</template>
			</a-table>
			<div class="documentFt">
			  <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
			</div>
		</GModule>
	</div>
</template>

<script>
export default{
	data(){
		return{
			columnsHe: [{
			    title: '序号',
			    width: 50,
			    dataIndex: 'id',
			    fixed: 'left',
			}, {
				title: '项目名称',
				width: 100,
				dataIndex: 'name'
			}, {
				title: '项目完成度（%）',
				width: 60,
				dataIndex: 'completion'
			}, {
				title: '团队成员',
				width: 200,
				dataIndex: 'team_arr'
			}, {
				title: '创建时间',
				width: 100,
				dataIndex: 'wait_time_text'
			}, {
				title: '相关地址链接',
				width: 150,
				dataIndex: 'urls_arr',
				fixed: 'right'
			}],
			total:0 ,// 条数
			limit:10, 
			page:1,
			list:[]
		}
	},
	computed:{
		isFresh(){
			return this.$store.state.isFresh
		},
		proId(){
			return this.$store.state.proId  
		},
	},
	watch:{
		isFresh(val,oldvalue){
			// 添加后刷新数据
			if(val){
				// 重置数据，刷新列表
				this.page = 1 
				this.$store.commit('setIsFresh',0)
				this.getList()
			}
		},
		proId(val,oldvalue){
			if(val){
				this.page = 1 
				this.getList()
			}
		}
	},
	created() {
		this.getList()
	},
	methods:{
		openAdd(){
			this.$store.commit('setIsManagerAdd',1)
		},
		async getList(){
			// 请求项目管理列表
			let res = await this.$utils.api.post({
				url:'/project/wait_index',
				data:{
					limit:this.limit,
					page:this.page
				},
				result:1
			})
			if(res.code == 1){
				this.total = res.data.total
				this.list = res.data.rows
			}
		},
		pageChange(page,pageSize){
			// 切换页码时查询列表
			this.page = page
			this.getList()
		},
	}
}
</script>

<style scoped lang="scss">
.container{
	margin: 20px;
	padding: 0;
	overflow-x: hidden;
	.team{
		display: flex;
		flex-wrap: wrap;
	}
	.documentFt{
		margin-top: 20px;
	}
}
</style>